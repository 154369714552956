// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aanbod-js": () => import("./../../../src/pages/aanbod.js" /* webpackChunkName: "component---src-pages-aanbod-js" */),
  "component---src-pages-aanbod-strapi-property-slug-js": () => import("./../../../src/pages/aanbod/{StrapiProperty.slug}.js" /* webpackChunkName: "component---src-pages-aanbod-strapi-property-slug-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-strapi-article-slug-js": () => import("./../../../src/pages/blog/{StrapiArticle.slug}.js" /* webpackChunkName: "component---src-pages-blog-strapi-article-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-syndicus-js": () => import("./../../../src/pages/syndicus.js" /* webpackChunkName: "component---src-pages-syndicus-js" */)
}

